import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { CSSObject, Theme, useTheme } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import { Introduction } from 'app/layout/Introduction';
import { getAllowedNavigationItems } from 'app/routerDefinition';

import { authSelector } from 'features/auth/authSlice';

import { RouteItem } from 'types/RouteItem';

const drawerWidth = 234;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: { width: `calc(${theme.spacing(6)} + 1px)` },
});
export const SideBar = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const location = useLocation();
  const selectAuth = useSelector(authSelector);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const isActive = (route: RouteItem) => {
    return route.path === location.pathname;
  };

  const allowedNavigationItems = getAllowedNavigationItems(selectAuth.user?.role);

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        }),
      }}
    >
      {/* Toolbar used to align with app-bar */}
      <Toolbar />

      <List>
        {/* Toggle NavDrawer */}
        <ListItem
          button
          onClick={toggleDrawer}
          sx={{
            color: theme.palette.primary.main,
            width: '90%',
            pl: 1.5,
            mx: 'auto',
            '& svg': {
              color: theme.palette.primary.main,
            },
          }}
        >
          <ListItemIcon>
            <MenuIcon />
          </ListItemIcon>
        </ListItem>

        {allowedNavigationItems.map((route: RouteItem, index) => {
          const Icon = route.iconComponent;

          return (
            <NavLink to={route.path} key={index} style={{ textDecoration: 'none' }}>
              <ListItem
                button
                sx={{
                  color: theme.palette.primary.main,
                  width: '90%',
                  mx: 'auto',
                  pl: 1.5,
                  '& svg': {
                    color: theme.palette.primary.main,
                  },
                  ...(isActive(route) && {
                    color: theme.palette.common.white,
                    backgroundColor: '#C70000',
                    borderRadius: '5px',
                    '& svg': {
                      color: theme.palette.common.white,
                    },
                    '&:hover': {
                      backgroundColor: '#C70000',
                    },
                  }),
                }}
              >
                <ListItemIcon sx={{ minWidth: '37px' }}>{Icon && <Icon />}</ListItemIcon>
                <ListItemText>{route.title}</ListItemText>
              </ListItem>
            </NavLink>
          );
        })}
      </List>
      <Introduction sideBarCurrentState={open} onClickFloatButton={toggleDrawer}></Introduction>
    </Drawer>
  );
};

export default SideBar;
