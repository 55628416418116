import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Fab, Link, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { appSettings } from 'app/appSettings';

export const Introduction = (props: any) => {
  const sideBarCurrentState = props.sideBarCurrentState;
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        overflow: 'hidden',
        textAlign: 'center',
        py: '8px',
      }}
    >
      {sideBarCurrentState && (
        <Box>
          <Link href={`${appSettings.clientUrl}/support/home`} color="inherit" underline="none" target="_blank">
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Questions? Contact Us:
            </Typography>
            <Typography variant="body1">Simplicity Keystone</Typography>
            <Typography variant="body1">(404) 907-1882</Typography>
            <Typography variant="body1">kbg.portal@simplicitygroup.com</Typography>
          </Link>
        </Box>
      )}
      {!sideBarCurrentState && (
        <Box>
          <Tooltip title="Have a question? Submit a ticket">
            <Link href={`${appSettings.clientUrl}/support/home`} color="inherit" underline="none" target="_blank">
              <Fab
                sx={{
                  mx: 'auto',
                }}
                size="small"
                color="primary"
              >
                <QuestionMarkIcon />
              </Fab>
            </Link>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

export default Introduction;
